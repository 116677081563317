import { auth } from "config/firebaseClient";
import {
  GoogleAuthProvider,
  onAuthStateChanged,
  signInWithCredential,
} from "firebase/auth";
import { signIn, useSession } from "next-auth/react";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import MaxSizeLoading from "./MaxSizeLoading";

function Auth({ children }) {
  // if `{ required: true }` is supplied, `status` can only be "loading" or "authenticated"
  const { data: session, status } = useSession({
    required: true,
    onUnauthenticated() {
      // The user is not authenticated, sign in via Google.
      // Pass in optional callbackUrl after user successfully signs in.
      signIn("google");
    },
  });

  useEffect(() => {
    if (session?.error) {
      signIn("google"); // Force sign in to hopefully resolve any errors
    }
  }, [session?.error]);

  // Is overall auth process complete? I.e., is there a valid user session using NextAuth, and is the user logged in via Firebase Auth on the client?
  const [authComplete, setAuthComplete] = useState(false);

  useEffect(() => {
    // If the nextAuthSession is still "loading", then just return, because everything is predicated on having that nextAuthSession.
    if (status === "loading") return;
    // Flag variable used to cancel state updates if the component is unmounted.
    let didCancel = false;
    // Will be used to hold the unsubscribe function returned by the Firebase authStateChanged subscription. Used to cancel subscription in useEffect cleanup.
    let unsubscribe;
    // Because we passed "require: true" to useSession, nextAuthSession is always non-null once status is no longer "loading". Checking for the existence of idToken is almost certainly unnecessary, but during development I ran into issues where I hadn't been passing the idToken to the nextAuthSession, so might as well as coverage for that case.
    if (session?.idToken && session?.user?.email) {
      // Check to see if there is an existing firebase user who has been authenticated.
      unsubscribe = onAuthStateChanged(auth, async (user) => {
        // If there is no authenticated user, or the authenticated Firebase user doesn't match the NextAuth session user, attempt to use the Google idToken generated by NextAuth to login to Firebase.
        if (!user || user.email !== session.user.email) {
          setAuthComplete(false);
          // This login flow will trigger the firebase onAuthStateChanged handler to be invoked, but this time there should be a user, leading to the else branch of this conditional.
          signInWithCredential(
            auth,
            GoogleAuthProvider.credential(session.idToken)
          ).catch(() => {
            signIn("google");
          });
        } else {
          if (!didCancel) {
            // There is a valid NextAuth session and an authenticated Firebase user, so we can consider auth complete.
            setAuthComplete(true);
          }
        }
      });
    } else {
      // The idToken isn't present on the nextAuthSession, so reinitiate the login flow for Google and this should correct the issue.
      signIn("google");
    }
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
      didCancel = true;
    };
  }, [status, session?.idToken, session?.user?.email]);

  if (!authComplete || status === "loading") {
    return <MaxSizeLoading />;
  }

  return children;
}

Auth.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Auth;
